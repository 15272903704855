import AppPromoBanner from "@/components/app-promo-banner";
import React from "react";
import withRouter from "@/components/functional-hook-wrapper";

import styles from './index.module.css';
import { contactForm } from "@/services/users";

export class HelpContact extends React.Component {
    state = {
        selected_department_index: 0,
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        zip: '',
        subject: '',
        message: '',
        data: {},
        turnstileToken: '',
        turnstileWidget: null as string | null,
    }

    router: any;

    private script: HTMLScriptElement | null = null;

    constructor(props: any) {
        super(props);
        this.router = props.router;
    }

    componentDidMount() {
        // Add Turnstile
        console.log('Mounting Turnstile...');
        this.script = document.createElement('script');
        this.script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit&onload=onloadTurnstileCallback";
        
        // Define the callback globally
        (window as any).onloadTurnstileCallback = () => {
            console.log('Turnstile script loaded');
            try {
                const container = document.getElementById('turnstile-widget');
                console.log('Container found:', !!container);
                // @ts-ignore
                const widgetId = window.turnstile.render('#turnstile-widget', {
                    sitekey: '0x4AAAAAAA2PTRGynlzlhPcI',
                    theme: 'light',
                    size: 'normal',
                    callback: (token: string) => {
                        console.log('Turnstile callback received token');
                        this.setState({ turnstileToken: token });
                    },
                    'expired-callback': () => {
                        console.log('Turnstile token expired');
                        this.setState({ turnstileToken: '' });
                    },
                    'error-callback': () => {
                        console.error('Turnstile error occurred');
                        this.setState({ turnstileToken: '' });
                    }
                });
                console.log('Widget rendered with ID:', widgetId);
                this.setState({ turnstileWidget: widgetId });
            } catch (error) {
                console.error('Error rendering Turnstile widget:', error);
            }
        };

        document.body.appendChild(this.script);
    }

    componentWillUnmount() {
        try {
            if (this.state.turnstileWidget) {
                // @ts-ignore
                window.turnstile?.remove(this.state.turnstileWidget);
            }
            if (this.script && document.body.contains(this.script)) {
                document.body.removeChild(this.script);
            }
            // Clean up the global callback
            delete (window as any).onloadTurnstileCallback;
        } catch (error) {
            console.error('Error cleaning up Turnstile:', error);
        }
    }

    private submitContactForm() {
        if (!this.state.turnstileToken) {
            alert('Please complete the security check');
            return;
        }

        if (this.state.first_name.length === 0 || this.state.last_name.length === 0 ||
            this.state.email.length === 0 || this.state.phone.length === 0 || !this.state.subject || this.state.message.length === 0 /* Add other required fields here */) {
            alert('Please fill out all required fields.');
            return;
        }

        contactForm(
            this.state.selected_department_index, 
            this.state.first_name, 
            this.state.last_name, 
            this.state.email, 
            this.state.phone, 
            this.state.address_line_1 + ' ' + this.state.address_line_2,
            this.state.city,
            this.state.state,
            this.state.zip,
            this.state.subject,
            this.state.message,
            this.state.data,
        )
        alert('Your message has been sent!');
    }

    private loadOptions() {
        switch (this.state.selected_department_index) {
            case 0:
                return
            case 8:
                return (
                    <>
                        <div className={styles.header}>Advertising*</div>
                        <div className={styles.conditional_form}>
                            <select className={styles.select} onChange={(event) => { this.setState({ subject: event.target.value }) }}>
                                <option>Select a Subject Topic*</option>
                                <option value="Online Giveaway">Online Giveaway</option>
                                <option value="Sponsorship Request">Sponsorship Request</option>
                                <option value="Advertising Opportunity">Advertising Opportunity</option>
                                <option value="Sales Promotion / Inquiry">Sales Promotion / Inquiry</option>
                                <option value="Comment About a Commercial">Comment About a Commercial</option>
                                <option value="Other">Other (Enter Below)</option>
                            </select>
                        </div>
                    </>
                )
            case 7:
                return (
                    <>
                        <div className={styles.header}>Bradley Street Cafe*</div>
                        <div className={styles.conditional_form}>
                            <select className={styles.select} onChange={(event) => { this.setState({ subject: event.target.value }) }}>
                                <option selected disabled hidden>Select a Subject Topic*</option>
                                <option value="New Product Inquiry">New Product Inquiry</option>
                                <option value="Quality / Safety Concern">Quality / Safety Concern</option>
                                <option value="Product I Wish You Would Sell">Product I Wish You Would Sell</option>
                                <option value="Pricing Concerns / Inquiries">Pricing Concerns / Inquiries</option>
                                <option value="Other">Other (Enter Below)</option>
                            </select>
                        </div>
                    </>
                )
            case 6:
                return (
                    <>
                        <div className={styles.header}>Card Services - Fleet Fueling Cards*</div>
                        <div className={styles.conditional_form}>
                            <select className={styles.select} onChange={(event) => { this.setState({ subject: event.target.value }) }}>
                                <option selected disabled hidden>Select a Subject Topic*</option>
                                <option value="Dispute a Charge">Dispute a Charge</option>
                                <option value="Request a Receipt">Request a Receipt</option>
                                <option value="Inquire About a Specific Transaction">Inquire About a Specific Transaction</option>
                                <option value="Other">Other (Enter Below)</option>
                            </select>
                        </div>
                    </>
                )
            case 1:
                return (
                    <>
                        <div className={styles.header}>Kwik Fill Card Services*</div>
                        <div className={styles.conditional_form}>
                            <input className={styles.input} style={{marginBottom: 18}} placeholder="Account Number*" onChange={(event) => {
                                this.setState({ data: { ...this.state.data, account_number: event.target.value } })
                            }} />

                            <select className={styles.select} onChange={(event) => { this.setState({ subject: event.target.value }) }}>
                                <option selected disabled hidden>Select a Subject Topic*</option>
                                <option value="Statement Inquiry">Statement Inquiry</option>
                                <option value="Copy of Receipt Request">Copy of Receipt Request</option>
                                <option value="Credit Limit Inquiry">Credit Limit Inquiry</option>
                                <option value="KwikPAY Inquiry">KwikPAY Inquiry</option>
                                <option value="Account Information Change">Account Information Change</option>
                                <option value="Kwik Fill Charge / Fleet Card">Kwik Fill Charge / Fleet Card</option>
                                <option value="Lost or Stolen Cards">Lost or Stolen Cards</option>
                                <option value="Balance Inquiry">Balance Inquiry</option>
                                <option value="Other">Other (Enter Below)</option>
                            </select>
                        </div>
                    </>
                )
            case 2:
                return (
                    <>
                        <div className={styles.header}>Card / Debit Card Services*</div>
                        <div className={styles.conditional_form}>
                            <select className={styles.select} onChange={(event) => { this.setState({ subject: event.target.value }) }}>
                                <option selected disabled hidden>Select a Subject Topic*</option>
                                <option value="Dispute a Charge">Dispute a Charge</option>
                                <option value="Request a Receipt">Request a Receipt</option>
                                <option value="Inquire About a Specific Transaction">Inquire About a Specific Transaction</option>
                                <option value="Other">Other (Enter Below)</option>
                            </select>
                        </div>

                        <div className={styles.conditional_form}>
                            <select className={styles.select} onChange={(event) => { 
                                this.setState({ data: { ...this.state.data, card_type: event.target.value } })
                            }}>
                                <option selected disabled hidden>Card Type</option>
                                <option value="Visa">Visa</option>
                                <option value="Mastercard">Mastercard</option>
                                <option value="American Express">American Express</option>
                                <option value="Discover">Discover</option>
                                <option value="Voyager">Voyager</option>
                                <option value="Wright Express">Wright Express</option>
                                <option value="Debit">Debit</option>
                                <option value="Other">Other (Enter Below)</option>
                            </select>

                            <input className={styles.input} style={{marginBottom: 18}} placeholder="Last 4 Digits of Card" onChange={(event) => {
                                this.setState({ data: { ...this.state.data, last_four: event.target.value } })
                            }} />
                            <input className={styles.input} style={{marginBottom: 18}} placeholder="Kwik Fill Store #" onChange={(event) => {
                                this.setState({ data: { ...this.state.data, store_number: event.target.value } })
                            }} />
                            <input className={styles.input} style={{marginBottom: 18}} placeholder="Transaction Date" onChange={(event) => {
                                this.setState({ data: { ...this.state.data, transaction_date: event.target.value } })
                            }} />
                        </div>
                    </>
                )
            case 3:
                return (
                    <>
                        <div className={styles.header}>Customer Service*</div>
                        <div className={styles.conditional_form}>
                            <select className={styles.select} onChange={(event) => { this.setState({ subject: event.target.value }) }}>
                                <option selected disabled hidden>Select a Subject Topic*</option>
                                <option value="Store / Employee Complaints">Store / Employee Complaints</option>
                                <option value="Product Complaints">Product Complaints</option>
                                <option value="Gift Card Questions">Gift Card Questions</option>
                                <option value="Store Locator Map Request">Store Locator Map Request</option>
                                <option value="Donation / Fundraising Request">Donation / Fundraising Request</option>
                                <option value="Promotions">Promotions</option>
                                <option value="Ethanol Questions">Ethanol Questions</option>
                                <option value="Employee Recognition / Praise">Employee Recognition / Praise</option>
                                <option value="Other">Other (Enter Below)</option>
                            </select>

                            <input className={styles.input} style={{marginBottom: 18}} placeholder="Which location did you visit?*" onChange={(event) => {
                                this.setState({ data: { ...this.state.data, location: event.target.value } })
                            }} />
                            <input className={styles.input} style={{marginBottom: 18}} placeholder="Date of visit*" onChange={(event) => {
                                this.setState({ data: { ...this.state.data, date_of_visit: event.target.value } })
                            }} />
                            <input className={styles.input} style={{marginBottom: 18}} placeholder="Time of visit*" onChange={(event) => {
                                this.setState({ data: { ...this.state.data, time_of_visit: event.target.value } })
                            }} />
                        </div>
                    </>
                )
            case 4:
                return (
                    <>
                        <div className={styles.header}>Employment Opportunities*</div>
                        <div className={styles.conditional_form}>
                            <select className={styles.select} onChange={(event) => { this.setState({ subject: event.target.value }) }}>
                                <option selected disabled hidden>Select a Subject Topic*</option>
                                <option value="Retail Store Employment">Retail Store Employment</option>
                                <option value="Corporate Offices Employment">Corporate Offices Employment</option>
                                <option value="Other">Other (Enter Below)</option>
                            </select>
                        </div>
                    </>
                )
            case 5:
                return (
                    <>
                        <div className={styles.header}>Merchandise Department*</div>
                        <div className={styles.conditional_form}>
                            <select className={styles.select} onChange={(event) => { this.setState({ subject: event.target.value }) }}>
                                <option selected disabled hidden>Select a Subject Topic*</option>
                                <option value="Cold Beverages">Cold Beverages</option>
                                <option value="JavaQuest Coffee / Hot Beverage">JavaQuest Coffee / Hot Beverage</option>
                                <option value="New Product Inquiry">New Product Inquiry</option>
                                <option value="Product Mix Feedback">Product Mix Feedback</option>
                                <option value="Products I Wish You Would Sell">Products I Wish You Would Sell</option>
                                <option value="Pricing Concerns / Inquiries">Pricing Concerns / Inquiries</option>
                                <option value="Other">Other (Enter Below)</option>
                            </select>
                        </div>
                    </>
                )
            case 9:
                return (
                    <>
                        <div className={styles.header}>Website Account Questions*</div>
                        <div className={styles.conditional_form}>
                            <select className={styles.select} onChange={(event) => { this.setState({ subject: event.target.value }) }}>
                                <option selected disabled hidden>Select a Subject Topic*</option>
                                <option value="Web Account Registration">Web Account Registration</option>
                                <option value="Web Account Verification">Web Account Verification</option>
                                <option value="Web Account Signing In">Web Account Signing In</option>
                                <option value="Web Account Passwords">Web Account Passwords</option>
                                <option value="Other">Other (Enter Below)</option>
                            </select>
                        </div>
                    </>
                )

        }
    }

    render() {
        return (
            <div className={styles.container}>
                <div>
                    <div className={styles.title}>Contact us! Questions, Comments, Concerns? Your opinion counts.</div>
                    <div className={styles.subtitle}>At Kwik Fill, we care about your thoughts. If you have questions, comments, or concerns, please take a moment to write us a note. Pick the appropriate department below and complete the communication form. We will reply promptly during weekly office hours. You might also find the answer to your question on our Frequently Asked Questions (<div style={{color: '#00924B', display: 'inline-block', cursor: 'pointer'}} onClick={() => { this.router.navigate('/help/faq') }}>FAQ</div>) page.</div>
                    <div>
                        <div className={styles.header}>Choose your department*</div>
                        <select className={styles.select} onChange={(event) => { 
                            this.setState({ 
                                selected_department_index: Number(event.target.value),
                                data: {}
                            }) 
                        }}>
                            <option value={0} selected disabled hidden>Please choose a department*</option>
                            <option value={8}>Advertising</option>
                            <option value={7}>Bradley Street Cafe</option>
                            <option value={6}>Card Services - Fleet Fueling Cards</option>
                            <option value={1}>Card Services - Kwik Fill Cards</option>
                            <option value={2}>Card Services - Other Credit Cards</option>
                            <option value={3}>Customer Service</option>
                            <option value={4}>Employment Opportunites</option>
                            <option value={5}>Merchandise Department</option>
                            <option value={9}>Website Account Questions</option>
                        </select>
                    </div>
                </div>

                <div className={styles.section_container}>
                    <div className={styles.form_container}>
                        <div>
                            <div className={styles.input_label}>First Name*</div>
                            <input className={styles.input} placeholder="First Name" value={this.state.first_name} onChange={(event) => { this.setState({ first_name: event.target.value })}} />
                        </div>
                        
                        <div>
                            <div className={styles.input_label}>Last Name*</div>
                            <input className={styles.input} placeholder="Last Name" value={this.state.last_name} onChange={(event) => { this.setState({ last_name: event.target.value })}} />
                        </div>

                        <div>
                            <div className={styles.input_label}>Email Address*</div>
                            <input className={styles.input} placeholder="Email Address" value={this.state.email} onChange={(event) => { this.setState({ email: event.target.value })}} />
                        </div>
                        
                        <div>
                            <div className={styles.input_label}>Phone Number*</div>
                            <input className={styles.input} placeholder="Phone Number" value={this.state.phone} onChange={(event) => { this.setState({ phone: event.target.value })}} />
                        </div>

                        <div>
                            <div className={styles.input_label}>Address Line 1*</div>
                            <input className={styles.input} placeholder="Address Line 1" value={this.state.address_line_1} onChange={(event) => { this.setState({ address_line_1: event.target.value })}} />
                        </div>
                        
                        <div>
                            <div className={styles.input_label}>Address Line 2</div>
                            <input className={styles.input} placeholder="Address Line 2" value={this.state.address_line_2} onChange={(event) => { this.setState({ address_line_2: event.target.value })}} />
                        </div>

                        <div>
                            <div className={styles.input_label}>City*</div>
                            <input className={styles.input} placeholder="City" value={this.state.city} onChange={(event) => { this.setState({ city: event.target.value })}} />
                        </div>
                        
                        <div>
                            <div className={styles.input_label}>State*</div>
                            <input className={styles.input} placeholder="State" value={this.state.state} onChange={(event) => { this.setState({ state: event.target.value })}} />
                        </div>

                        <div>
                            <div className={styles.input_label}>Zip*</div>
                            <input className={styles.input} placeholder="Zip Code" value={this.state.zip} onChange={(event) => { this.setState({ zip: event.target.value })}} />
                        </div>
                    </div>

                    <div style={{ marginTop: '10px', minHeight: '65px' }}>
                        <div id="turnstile-widget"></div>
                    </div>

                    { this.state.selected_department_index === 0 ?
                        <></>
                        :
                        <>
                            <div className={styles.conditional_section_container}>
                                { this.loadOptions() }

                                <div className={styles.text_box_container}>
                                    <textarea value={this.state.message} onChange={(event) => { this.setState({ message: event.target.value })}} className={styles.text_box} placeholder="Your Message*" rows={6}></textarea>
                                </div>

                                <div onClick={() => {
                                    this.submitContactForm()
                                }} className={styles.primary_button}>Submit</div>
                            </div>
                        </>
                    }

                </div>

                <div style={{marginTop: 120, marginBottom: 60}}>
                    <AppPromoBanner />
                </div>
            </div>
        );
    }
}

export default withRouter(HelpContact);