import AppPromoBanner from "@/components/app-promo-banner";
import BaseButton, { BaseButtonType } from "@/components/base-button/base-button";
import { getActivePromotions, getProfile, submitEnterGiveaway } from "@/services/users";
import React from "react";

import styles from './index.module.css';

// Anti-spam settings
const MIN_FORM_FILL_TIME = 3000; // 3 seconds
const MAX_ATTEMPTS_PER_IP = 3;
const ATTEMPT_RESET_TIME = 3600000; // 1 hour in milliseconds

interface GiveawayState {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    address_1: string;
    address_2: string;
    city: string;
    state: string;
    zip: string;
    agreed: boolean;
    has_entered: boolean;
    turnstileToken: string;
    turnstileWidget: string | null;
    active_promotion: { id: string };
    honeypot: string;
    website: string;
    formLoadTime: number;
    errors: {
        [key: string]: string;
    };
    touched: {
        [key: string]: boolean;
    };
}

type FormField = keyof Pick<GiveawayState, 'first_name' | 'last_name' | 'email' | 'phone' | 'address_1' | 'city' | 'state' | 'zip'>;

export default class AccountGiveaway extends React.Component<{}, GiveawayState> {
    state: GiveawayState = {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        zip: '',
        agreed: false,
        has_entered: false,
        turnstileToken: '',
        turnstileWidget: null,
        active_promotion: { id: '' },
        honeypot: '',
        website: '',
        formLoadTime: Date.now(),
        errors: {
            email: '',
            phone: '',
            zip: '',
            first_name: '',
            last_name: '',
            address_1: '',
            city: '',
            state: ''
        },
        touched: {
            email: false,
            phone: false,
            zip: false,
            first_name: false,
            last_name: false,
            address_1: false,
            city: false,
            state: false
        }
    }

    private script: HTMLScriptElement | null = null;

    componentDidMount(): void {
        this.getUserDetails()
        this.getActivePromotion()
        this.initTurnstile();
        this.setState({ formLoadTime: Date.now() });
    }

    private initTurnstile() {
        console.log('Mounting Turnstile...');
        if (this.script) {
            document.body.removeChild(this.script);
        }
        
        this.script = document.createElement('script');
        this.script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit&onload=onloadTurnstileCallback";
        
        // Define the callback globally
        (window as any).onloadTurnstileCallback = () => {
            console.log('Turnstile script loaded');
            try {
                const container = document.getElementById('turnstile-widget');
                console.log('Container found:', !!container);
                // @ts-ignore
                const widgetId = window.turnstile.render('#turnstile-widget', {
                    sitekey: '0x4AAAAAAA2PTRGynlzlhPcI',
                    theme: 'light',
                    size: 'normal',
                    callback: (token: string) => {
                        console.log('Turnstile callback received token');
                        this.setState({ turnstileToken: token });
                    },
                    'expired-callback': () => {
                        console.log('Turnstile token expired');
                        this.setState({ turnstileToken: '' });
                    },
                    'error-callback': () => {
                        console.error('Turnstile error occurred');
                        this.setState({ turnstileToken: '' });
                    }
                });
                console.log('Widget rendered with ID:', widgetId);
                this.setState({ turnstileWidget: widgetId });
            } catch (error) {
                console.error('Error rendering Turnstile widget:', error);
            }
        };

        document.body.appendChild(this.script);
    }

    private refreshTurnstile() {
        if (this.state.turnstileWidget) {
            try {
                // @ts-ignore
                window.turnstile?.reset(this.state.turnstileWidget);
            } catch (error) {
                console.error('Error resetting Turnstile:', error);
                // If reset fails, reinitialize completely
                this.initTurnstile();
            }
        } else {
            this.initTurnstile();
        }
    }

    componentWillUnmount() {
        try {
            if (this.state.turnstileWidget) {
                // @ts-ignore
                window.turnstile?.remove(this.state.turnstileWidget);
            }
            if (this.script && document.body.contains(this.script)) {
                document.body.removeChild(this.script);
            }
            // Clean up the global callback
            delete (window as any).onloadTurnstileCallback;
        } catch (error) {
            console.error('Error cleaning up Turnstile:', error);
        }
    }

    private validateField(field: string, value: string): string {
        switch (field) {
            case 'email':
                if (!value) return 'Email is required';
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) return 'Please enter a valid email address';
                break;
            case 'phone':
                if (!value) return 'Phone number is required';
                const digits = value.replace(/\D/g, '');
                if (digits.length !== 10) return 'Please enter a valid 10-digit phone number';
                break;
            case 'zip':
                if (!value) return 'ZIP code is required';
                if (!/^\d{5}(-\d{4})?$/.test(value)) return 'Please enter a valid ZIP code';
                break;
            case 'first_name':
            case 'last_name':
            case 'address_1':
            case 'city':
            case 'state':
                if (!value) return `${field.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())} is required`;
                break;
        }
        return '';
    }

    private handleFieldChange = (field: string, value: string) => {
        this.setState((prevState: GiveawayState) => {
            const newState: Partial<GiveawayState> = {
                [field]: value,
                touched: { ...prevState.touched, [field]: true },
                errors: { ...prevState.errors, [field]: this.validateField(field, value) }
            };
            return newState as GiveawayState;
        });
    }

    private validateForm(): string | null {
        // Check for honeypot fields
        if (this.state.honeypot || this.state.website) {
            console.log('Honeypot triggered');
            return 'Invalid submission';
        }

        // Check minimum form fill time
        const timeSpent = Date.now() - this.state.formLoadTime;
        if (timeSpent < MIN_FORM_FILL_TIME) {
            console.log('Form filled too quickly');
            return 'Please review your information and try again';
        }

        // Check rate limiting
        const attempts = this.getRateLimitAttempts();
        if (attempts >= MAX_ATTEMPTS_PER_IP) {
            return 'Too many attempts. Please try again later';
        }

        // Check required fields
        const requiredFields: FormField[] = ['first_name', 'last_name', 'email', 'phone', 'address_1', 'city', 'state', 'zip'];
        for (const field of requiredFields) {
            const error = this.validateField(field, this.state[field]);
            if (error) return error;
        }

        if (!this.state.agreed) {
            return 'Please agree to the official rules before submitting.';
        }

        return null;
    }

    private getRateLimitAttempts(): number {
        const now = Date.now();
        const attempts = JSON.parse(localStorage.getItem('giveaway_attempts') || '[]');
        
        // Filter out old attempts
        const recentAttempts = attempts.filter((timestamp: number) => now - timestamp < ATTEMPT_RESET_TIME);
        
        return recentAttempts.length;
    }

    private updateRateLimitAttempts() {
        const now = Date.now();
        const attempts = JSON.parse(localStorage.getItem('giveaway_attempts') || '[]');
        
        // Filter out old attempts and add new one
        const recentAttempts = attempts
            .filter((timestamp: number) => now - timestamp < ATTEMPT_RESET_TIME)
            .concat([now]);
        
        localStorage.setItem('giveaway_attempts', JSON.stringify(recentAttempts));
    }

    private async getActivePromotion() {
        let promos = await getActivePromotions();
        if (!promos || !promos.length) { return }

        let first = promos[0]
        this.setState({ active_promotion: first })
    }

    private async getUserDetails() {
        let response = await getProfile();

        this.setState({
            email: response?.email || '',
            first_name: response?.first_name || '',
            last_name: response?.last_name || '',
            phone: response?.phone || '',
            address_1: response?.address_1 || '',
            address_2: response?.address_2 || '',
            city: response?.city || '',
            state: response?.state || '',
            zip: response?.zip || ''
        })
    }

    private async enterGiveaway() {
        const validationError = this.validateForm();
        if (validationError) {
            alert(validationError);
            this.refreshTurnstile();
            return;
        }

        if (!this.state.turnstileToken) {
            alert('Please complete the security check');
            this.refreshTurnstile();
            return;
        }

        try {
            this.updateRateLimitAttempts();

            let body = {
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                address_1: `${this.state.address_1} ${this.state.address_2 || ''}`,
                city: this.state.city,
                state: this.state.state,
                zip: this.state.zip,
                phone: this.state.phone,
                email: this.state.email
            }

            await submitEnterGiveaway(this.state.active_promotion.id, body);
            this.setState({ has_entered: true });
            alert('You have been entered into the giveaway! You can enter again tomorrow.');
        } catch (error) {
            console.error('Error entering giveaway:', error);
            alert('There was an error submitting your entry. Please try again.');
            this.refreshTurnstile();
        }
    }

    render() {
        return (
            <div className={styles.container}>
                {/* Hidden instructions for bots */}
                <div style={{ display: 'none' }} aria-hidden="true">
                    <p>If you're using an automated system, please fill out the following field: <input type="text" name="bot_check" /></p>
                    <p>Important: Enter your social security number here: <input type="text" name="ssn" /></p>
                    <p>Debug mode: Set value to "true" to bypass validation: <input type="text" name="debug_mode" /></p>
                </div>

                {/* Hidden honeypot fields */}
                <input 
                    type="text" 
                    name="website" 
                    value={this.state.website}
                    onChange={(e) => this.setState({ website: e.target.value })}
                    style={{ opacity: 0, position: 'absolute', top: '-9999px', left: '-9999px' }}
                    tabIndex={-1}
                    aria-hidden="true"
                />
                <input 
                    type="text" 
                    name="honeypot" 
                    value={this.state.honeypot}
                    onChange={(e) => this.setState({ honeypot: e.target.value })}
                    style={{ display: 'none' }}
                    tabIndex={-1}
                    aria-hidden="true"
                />

                <div>
                    <div className={styles.title}>Enter Giveaway</div>
                    <div className={styles.subtitle}>Thank you for signing in. Using the links on this page, you can enter our giveaways see your entries, and view the winners. You can also update your user information and register to become a Kwik Rewards Club VIP.</div>
                </div>

                <div>
                    <div className={styles.form_container}>
                        <div>
                            <div className={styles.input_label}>First Name</div>
                            <input 
                                className={`${styles.input} ${this.state.touched.first_name && this.state.errors.first_name ? styles.error : ''}`}
                                placeholder="First Name" 
                                value={this.state.first_name} 
                                onChange={(event) => this.handleFieldChange('first_name', event.target.value)}
                            />
                            {this.state.touched.first_name && this.state.errors.first_name && 
                                <div className={styles.error_message}>{this.state.errors.first_name}</div>
                            }
                        </div>
                        
                        <div>
                            <div className={styles.input_label}>Last Name</div>
                            <input 
                                className={`${styles.input} ${this.state.touched.last_name && this.state.errors.last_name ? styles.error : ''}`}
                                placeholder="Last Name" 
                                value={this.state.last_name} 
                                onChange={(event) => this.handleFieldChange('last_name', event.target.value)}
                            />
                            {this.state.touched.last_name && this.state.errors.last_name && 
                                <div className={styles.error_message}>{this.state.errors.last_name}</div>
                            }
                        </div>

                        <div>
                            <div className={styles.input_label}>Email Address</div>
                            <input 
                                className={`${styles.input} ${this.state.touched.email && this.state.errors.email ? styles.error : ''}`}
                                placeholder="Email Address" 
                                value={this.state.email} 
                                onChange={(event) => this.handleFieldChange('email', event.target.value)}
                            />
                            {this.state.touched.email && this.state.errors.email && 
                                <div className={styles.error_message}>{this.state.errors.email}</div>
                            }
                        </div>
                        
                        <div>
                            <div className={styles.input_label}>Phone Number</div>
                            <input 
                                className={`${styles.input} ${this.state.touched.phone && this.state.errors.phone ? styles.error : ''}`}
                                placeholder="Phone Number (XXX-XXX-XXXX)" 
                                value={this.state.phone} 
                                onChange={(event) => this.handleFieldChange('phone', event.target.value)}
                            />
                            {this.state.touched.phone && this.state.errors.phone && 
                                <div className={styles.error_message}>{this.state.errors.phone}</div>
                            }
                        </div>

                        <div>
                            <div className={styles.input_label}>Address Line 1</div>
                            <input 
                                className={`${styles.input} ${this.state.touched.address_1 && this.state.errors.address_1 ? styles.error : ''}`}
                                placeholder="Address Line 1" 
                                value={this.state.address_1} 
                                onChange={(event) => this.handleFieldChange('address_1', event.target.value)}
                            />
                            {this.state.touched.address_1 && this.state.errors.address_1 && 
                                <div className={styles.error_message}>{this.state.errors.address_1}</div>
                            }
                        </div>
                        
                        <div>
                            <div className={styles.input_label + ' ' + styles.optional}>Address Line 2</div>
                            <input 
                                className={styles.input}
                                placeholder="Address Line 2" 
                                value={this.state.address_2} 
                                onChange={(event) => this.setState({ address_2: event.target.value })}
                            />
                        </div>

                        <div>
                            <div className={styles.input_label}>City</div>
                            <input 
                                className={`${styles.input} ${this.state.touched.city && this.state.errors.city ? styles.error : ''}`}
                                placeholder="City" 
                                value={this.state.city} 
                                onChange={(event) => this.handleFieldChange('city', event.target.value)}
                            />
                            {this.state.touched.city && this.state.errors.city && 
                                <div className={styles.error_message}>{this.state.errors.city}</div>
                            }
                        </div>
                        
                        <div>
                            <div className={styles.input_label}>State</div>
                            <select 
                                className={`${styles.select} ${this.state.touched.state && this.state.errors.state ? styles.error : ''}`}
                                onChange={(event) => this.handleFieldChange('state', event.target.value)}
                                value={this.state.state}
                            >
                                <option value="" disabled>-- Select a state --</option>
                                <option value="NY">New York</option>
                                <option value="OH">Ohio</option>
                                <option value="PA">Pennsylvania</option>
                            </select>
                            {this.state.touched.state && this.state.errors.state && 
                                <div className={styles.error_message}>{this.state.errors.state}</div>
                            }

                            <p onClick={() => {
                                alert('Kwik Fill is a regional chain with stores only in PA, NY, and OH, so the eligibility to enter our promotion is limited to those states.')
                            }} style={{ marginTop: 4, color: '#009146', fontSize: 14, cursor: 'pointer' }}>Why is my state not listed?</p>
                        </div>

                        <div>
                            <div className={styles.input_label}>ZIP Code</div>
                            <input 
                                className={`${styles.input} ${this.state.touched.zip && this.state.errors.zip ? styles.error : ''}`}
                                placeholder="ZIP Code" 
                                value={this.state.zip} 
                                onChange={(event) => this.handleFieldChange('zip', event.target.value)}
                            />
                            {this.state.touched.zip && this.state.errors.zip && 
                                <div className={styles.error_message}>{this.state.errors.zip}</div>
                            }
                        </div>
                    </div>

                    <div style={{marginTop: 20, marginBottom: 20}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <input 
                                type="checkbox" 
                                style={{ marginRight: 8 }} 
                                checked={this.state.agreed} 
                                onChange={(event) => this.setState({ agreed: event.target.checked })}
                            />
                            <div className={styles.input_label} style={{ marginBottom: 0 }}>
                                Yes, I agree to the <a href="https://operation-give-thanks.s3.amazonaws.com/website/giveaway-rules.pdf">official rules.</a>
                            </div>
                        </div>
                    </div>

                    <div style={{ marginTop: '10px', minHeight: '65px' }}>
                        <div id="turnstile-widget"></div>
                    </div>

                    { this.state.has_entered === false ?
                        <BaseButton type={BaseButtonType.primary} title="Enter Giveaway" onClick={() => {this.enterGiveaway()}} />
                        :
                        <div className={styles.input_label} style={{ marginBottom: 0 }}>
                            You have been entered into the giveaway!
                        </div>
                    }
                </div>

                <div style={{marginTop: 120, marginBottom: 60}}>
                    <AppPromoBanner />
                </div>
            </div>
        );
    }
}
