import AppPromoBanner from "@/components/app-promo-banner";
import React, { useState, useEffect } from 'react';
import styles from './index.module.css';

// Define the structure of a single winner
interface Winner {
    winner_id: number;
    promotion: string;
    first_name: string;
    last_name: string;
    city: string;
    state: string;
    prize: string;
}

// Define the structure for grouped giveaways
interface GiveawayGroup {
    [key: string]: Winner[];
}

export default function AccountWinners() {
    const [giveaways, setGiveaways] = useState<GiveawayGroup>({});

    useEffect(() => {
        fetchWinners();
    }, []);

    const fetchWinners = async () => {
        try {
            const response = await fetch('https://kwikfill-content.outerbase.workers.dev/query', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Starbase-Source': 'external',
                    'Authorization': 'Bearer u31fvb9h4ts41wkjc7zde7y68inruvde'
                },
                body: JSON.stringify({
                    sql: "select * from previous_winners order by winner_id desc"
                })
            });
            const data = await response.json();
            if (data.result) {
                processWinners(data.result);
            }
        } catch (error) {
            console.error('Error fetching winners:', error);
        }
    };

    const processWinners = (items: Winner[]) => {
        const grouped = items.reduce<GiveawayGroup>((acc, item) => {
            acc[item.promotion] = acc[item.promotion] || [];
            acc[item.promotion].push(item);
            return acc;
        }, {});

        setGiveaways(grouped);
    };

    return (
        <div className={styles.container}>
            <div className={styles.title}>Previous Winners</div>
            <div className={styles.subtitle}>Below are the past Kwik Fill Giveaway Winners.</div>

            {Object.entries(giveaways).map(([giveawayName, winners], index) => (
                <AccordionItem key={index} title={giveawayName} winners={winners} />
            ))}
        </div>
    );
}

interface AccordionItemProps {
    title: string;
    winners: Winner[];
}

const AccordionItem: React.FC<AccordionItemProps> = ({ title, winners }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={styles.section_container}>
            <div className={styles.row}>
                <div className={styles.giveaway_name}>{title}</div>
                <div className={styles.toggle_visibility} onClick={() => setIsOpen(!isOpen)}>
                    {isOpen ? 'Hide' : 'View'} Winners
                </div>
            </div>

            {isOpen && (
                <div className={styles.details}>
                    {winners.map((winner, index) => (
                        <div key={index} className={styles.details_list}>
                            <div className={styles.name}>{winner.first_name} {winner.last_name} ({winner.city}, {winner.state})</div>
                            <div> - {winner.prize}</div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
