import AppPromoBanner from "@/components/app-promo-banner";
import BaseButton, { BaseButtonType } from "@/components/base-button/base-button";
import { getProfile, setProfile, updatePassword } from "@/services/users";
import React from "react";

import styles from './index.module.css';

export default class AccountDetails extends React.Component {
    state = {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        zip: '',
        current_password: '',
        new_password: '',
        new_password_confirm: '',
    }

    componentDidMount(): void {
        this.getUserDetails()
    }

    private async getUserDetails() {
        let response = await getProfile();

        this.setState({
            email: response?.email || '',
            first_name: response?.first_name || '',
            last_name: response?.last_name || '',
            phone: response?.phone || '',
            address_1: response?.address_1 || '',
            address_2: response?.address_2 || '',
            city: response?.city || '',
            state: response?.state || '',
            zip: response?.zip || ''
        })
    }

    private async updateUserDetails() {
        await setProfile(this.state.first_name, this.state.last_name, this.state.address_1, this.state.address_2, this.state.city, this.state.state, this.state.zip, this.state.phone);
    }

    private async changePassword() {
        // TODO: will need to run call to get the user's current password
        // May just need to check if new passwords match then send in a call and the backend will verify the match to current password then update
        if (this.state.new_password === this.state.new_password_confirm) {
            let response = await updatePassword(this.state.current_password, this.state.new_password);
            console.log('password update: ', response)
        }
    }

    private async deleteAccount() {
        console.log('deleting account');
    }

    render() {
        return (
            <div className={styles.container}>
                <div>
                    <div className={styles.title}>Account Details</div>
                    <div className={styles.subtitle}>Thank you for signing in. Using the links on this page, you can enter our giveaways see your entries, and view the winners. You can also update your user information and register to become a Kwik Rewards Club VIP.</div>
                </div>

                <div className={styles.section_container}>
                    <div className={styles.form_container}>
                        <div>
                            <div className={styles.input_label}>First Name</div>
                            <input className={styles.input} placeholder="First Name" value={this.state.first_name} onChange={(event) => {
                                this.setState({ first_name: event.target.value })
                            }} />
                        </div>
                        
                        <div>
                            <div className={styles.input_label}>Last Name</div>
                            <input className={styles.input} placeholder="Last Name" value={this.state.last_name} onChange={(event) => {
                                this.setState({ last_name: event.target.value })
                            }} />
                        </div>

                        <div>
                            <div className={styles.input_label}>Email Address</div>
                            <input className={styles.input} placeholder="Email Address" disabled={true} style={{opacity: 0.5}} value={this.state.email} onChange={(event) => {
                                this.setState({ email: event.target.value })
                            }} />
                        </div>
                        
                        <div>
                            <div className={styles.input_label}>Phone Number</div>
                            <input className={styles.input} placeholder="Phone Number" value={this.state.phone} onChange={(event) => {
                                this.setState({ phone: event.target.value })
                            }} />
                        </div>

                        <div>
                            <div className={styles.input_label}>Address Line 1</div>
                            <input className={styles.input} placeholder="Address Line 1" value={this.state.address_1} onChange={(event) => {
                                this.setState({ address_1: event.target.value })
                            }} />
                        </div>
                        
                        <div>
                            <div className={styles.input_label}>Address Line 2</div>
                            <input className={styles.input} placeholder="Address Line 2" value={this.state.address_2} onChange={(event) => {
                                this.setState({ address_2: event.target.value })
                            }} />
                        </div>

                        <div>
                            <div className={styles.input_label}>City</div>
                            <input className={styles.input} placeholder="City" value={this.state.city} onChange={(event) => {
                                this.setState({ city: event.target.value })
                            }} />
                        </div>
                        
                        <div>
                            <div className={styles.input_label}>State</div>
                            <input className={styles.input} placeholder="State" value={this.state.state} onChange={(event) => {
                                this.setState({ state: event.target.value })
                            }} />
                        </div>

                        <div>
                            <div className={styles.input_label}>Zip</div>
                            <input className={styles.input} placeholder="Zip Code" value={this.state.zip} onChange={(event) => {
                                this.setState({ zip: event.target.value })
                            }} />
                        </div>
                    </div>

                    <div style={{marginTop: 20, marginBottom: 20}}>
                    </div>

                    <BaseButton type={BaseButtonType.primary} title='Save Changes' onClick={() => {this.updateUserDetails()}} />
                </div>

                {/* Change Password Section */}
                <div className={styles.section_container}>
                    <div>
                        <div className={styles.title}>Change Password</div>
                        <div className={styles.subtitle}>Change your password.</div>
                    </div>

                    <div className={styles.form_container} style={{marginBottom: 20}}>
                        <div>
                            <div className={styles.input_label}>Current Password</div>
                            <input className={styles.input} placeholder="Enter current password" value={this.state.current_password} onChange={(event) => {
                                this.setState({current_password: event.target.value})
                            }} />
                        </div>
                        
                        <div>
                        </div>

                        <div>
                            <div className={styles.input_label}>New Password</div>
                            <input className={styles.input} placeholder="Enter new password" value={this.state.new_password} onChange={(event) => {
                                this.setState({new_password: event.target.value})
                            }} />
                        </div>
                        
                        <div>
                            <div className={styles.input_label}>Confirm Password</div>
                            <input className={styles.input} placeholder="Re-enter new password" value={this.state.new_password_confirm} onChange={(event) => {
                                this.setState({new_password_confirm: event.target.value})
                            }} />
                        </div>
                    </div>

                    <BaseButton type={BaseButtonType.primary} title='Update Password' onClick={() => {this.changePassword()}} />
                </div>

                {/* Delete Account Section */}
                <div>
                    <div style={{marginBottom: 20}}>
                        <div className={styles.title}>Delete Account</div>
                        <div className={styles.subtitle}>This is a permanent account deletion, this cannot be undone.</div>
                    </div>

                    <BaseButton type={BaseButtonType.primaryCancel} title='Delete Account' onClick={() => {this.deleteAccount()}} />
                </div>

                <div style={{marginTop: 120, marginBottom: 60}}>
                    <AppPromoBanner />
                </div>
            </div>
        );
    }
}
