import axios_instance from "./networking";

const loginUser = async (email: string, password: string): Promise<any> => {
    return await axios_instance.post('/auth/login', {email: email, password: password}).then(function (response: any) {
        const data = response.data.response;
        localStorage.setItem('token', data.token)
        localStorage.setItem('user_id', data.user_id)
        return data
    }).catch(function (error: any) {
        console.log('Networking Error: Could not login user `loginUser`. ', error)
    })
}

const setProfile = async (first_name: string, last_name: string, address_1: string, address_2: string, city: string, state: string, zip: string, phone: string): Promise<any> => {
    return await axios_instance.put('/me/profile', {
        first_name: first_name,
        last_name: last_name,
        address_1: address_1,
        address_2: address_2,
        city: city,
        state: state,
        zip: zip,
        phone: phone
    }).then(function (response: any) {
        const data = response.data.response;
        return data
    }).catch(function (error: any) {
        console.log('Networking Error: could not set profile `setProfile`. ', error)
    })
}
 
const getProfile = async (): Promise<any> => {
    return await axios_instance.get('/me/profile').then(function (response: any) {
        const data = response.data.response;
        
        if (data?.first_name) {
            localStorage.setItem('first_name', data.first_name);
        }

        return data
    }).catch(function (error: any) {
        console.log('Networking Error: Could not get profile `getProfile`. ', error)
    })
}

const getStoreLocations = async(): Promise<any> => {
    return await axios_instance.get('/store?size=1000').then(function (response: any) {
        const data = response.data.response.items;

        let stores = data.map((store: any) => {
            return {
                address1: store.data.sAddr1,
                address2: store.data.sAddr2,
                city: store.data.sCity,
                state: store.data.sState,
                zip: store.data.sZip,
                latitude: store.data.sLat,
                longitude: store.data.sLon,
                store_number: store.data.sStoreNumber,
                phone: store.data.sPhone,
                gas_price: store.gas_price,
                diesel_price: store.diesel_price,
            }
        })

        return stores
    }).catch(function (error: any) {
        console.log('Networking Error: could not load store locations `getStoreLocations`', error);
    })
}

const registerUser = async (email: string, password: string): Promise<any> => {
    return await axios_instance.post('/auth/register', {email: email, password: password}).then(function (response: any) {
        const data = response.data.response;
        localStorage.setItem('token', data.token)
        localStorage.setItem('user_id', data.user_id)
        return data
    }).catch(function (error: any) {
        console.log('Networking Error: Could not register user `registerUser`. ', error)
    })
}

const updatePassword = async (current_password: string, new_password: string): Promise<any> => {
    return await axios_instance.post('/me/profile/password', {old_password: current_password, new_password: new_password}).then(function (response: any) {
        const data = response.data.response;
        return data
    }).catch(function (error: any) {
        console.log('Networking Error: Could not update password `updatePassword`', error)
    })
}

const resetPassword = async (email: string): Promise<any> => {
    return await axios_instance.post('/auth/password_reset/request', {email: email}).then(function (response: any) {
        const data = response.data.response;
        return data
    }).catch(function (error: any) {
        console.log('Networking Error: Could not reset password `resetPasssoword`. ', error)
    })
}

const submitResetPassword = async (reset_token: string, password: string): Promise<any> => {
    return await axios_instance.post('/auth/password_reset/submit', {reset_token: reset_token, password: password}).then(function (response: any) {
        const data = response.data.response;
        return data
    }).catch(function (error: any) {
        console.log('Networking Error: Could not submit reset password `submitResetPasssoword`. ', error)
        return Promise.reject(error);
    })
}

const submitEnterGiveaway = async (promotion_id: string, body: any): Promise<any> => {
    const currentDate = new Date();
    const timestamp = currentDate.getTime();

    return await axios_instance.post(`/promotion/${promotion_id}/entry?cb=${timestamp}`, body).then(function (response: any) {
        const data = response.data.response;
        return data
    }).catch(function (error: any) {
        console.log('Networking Error: Could not submit giveaway entry `submitEnterGiveaway`. ', error)
    })
}

const getActivePromotions = async (): Promise<any> => {
    return await axios_instance.get('/promotion?active_only=true').then(function (response: any) {
        const data = response.data.response.items;
        return data
    }).catch(function (error: any) {
        console.log('Networking Error: Could not get active promotions `getActivePromotions`. ', error)
    })
}

const listPastWinners = async(): Promise<any> => {
    return await axios_instance.get('/promotion/past_winner').then(function (response: any) {
        const data = response.data.response.items;
        return data
    }).catch(function (error: any) {
        console.log('Networking Error: Could not list past winners `listPastWinners`. ', error)
    })
}

const contactForm = async(
        department: number, 
        first_name: string, 
        last_name: string, 
        email: string, 
        phone: string, 
        address: string,
        city: string,
        state: string,
        zip: string,
        subject: string,
        message: string,
        data: any): Promise<any> => {
    let body = {
        department: department,
        first_name: first_name,
        last_name: last_name,
        email,
        phone,
        address,
        city,
        state,
        zip,
        customer_service: {
            ...data,
            subject,
            message
        }
    }

    return await axios_instance.post('/contact?include_ip=true', body).then(function (response: any) {
        const data = response.data.response;
        return data
    }).catch(function (error: any) {
        console.log('Networking Error: Could not submit contact form `contactForm`. ', error)
    })
}

const addToConstantContact = async (
    email: string,
    first_name: string,
    last_name: string,
    phone: string,
    address: string,
    city: string,
    state: string,
    zip: string,
    lists: string[]
): Promise<any> => {
    const body = {
        email_address: {
            address: email,
            permission_to_send: "explicit"
        },
        first_name,
        last_name,
        list_memberships: lists,
        create_source: "Account"
    };

    // Create a separate axios instance for Constant Contact API
}

export {
    submitEnterGiveaway,
    getActivePromotions,
    getProfile,
    getStoreLocations,
    listPastWinners,
    loginUser,
    registerUser,
    resetPassword,
    submitResetPassword,
    setProfile,
    updatePassword,
    contactForm,
    addToConstantContact
};
